import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {

  name: "forgot-password",
  mixin: [validationMixin],
  data() {
    return {
      disable:false,
      payload: {
        email: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      email: {
        required,
        email,
        maxLength: maxLength(50),
      },
    }
  },
  computed:{
    ...mapGetters({
      restorePasswordLoading:'auth/restorePasswordLoading'
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  methods:{
    ...mapMutations({
      changePopupForgotPassword: `auth/CHANGE_POPUP_FORGOT_PASSWORD`,
      changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
    }),
    ...mapActions({
      registration: `auth/REGISTRATION`,
      resetPassword:`auth/RESTORE_PASSWORD`
    }),
    submit(){
      this.$v.$touch();
      if (!this.$v.$invalid){
        this.disable = true
        this.resetPassword(this.payload).then(()=>{
          this.$toasted.success(this.$t('instructionSendOnEmail'));
          this.changePopupForgotPassword(false)
          this.disable = false
        }).catch(error =>{
          if (error.response.status === 400){
            this.disable = false
            this.$toasted.error(this.$t("wrongUser"));
          }
        })
      }
    },
    enter(){
      this.changePopupForgotPassword(false)
      this.changePopupLogin(true)
    }
  }
}